<template>
  <div class="serveses">
    <h4>
      <img src="@/assets/photos/Group.svg" alt="" />{{ parntservices.header }}
    </h4>
    <div class="row">
      <div
        class="col-lg-3 col-md-6 col-sm-12 col-xs-12 col-12"
        v-for="(ser, i) in servicefeatures"
        :key="i"
      >
        <div class="card-serveses">
          <img :src="ser.icon_link" alt="" />
          <h4>{{ ser.title }}</h4>
          <p>
            {{ ser.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "ServesesHome",
  data() {
    return {};
  },
  props: {
    parntservices: {
      type: Array,
      required: true,
    },
    servicefeatures: {
      type: Array,
      required: true,
    },
  },
};
</script>

<template>
  <div class="terms-use">
    <h4>
      <router-link to="/">الرئيسية</router-link>
      <i class="fa-solid fa-chevron-left"></i><span>شروط الأستخدام</span>
    </h4>
    <TermsUseCompo />
  </div>
</template>
<script>
import TermsUseCompo from "@/components/Global/TermsUse/TermsUseCompo.vue";
export default {
  name: "TermsUse",
  components: {
    TermsUseCompo,
  },
};
</script>
<style lang="scss" scoped>
.terms-use {
  h4 {
    margin-top: 12rem;
  }
}
</style>

<template>
  <div class="common-questions">
    <h4>
      <router-link to="/">الرئيسية</router-link>
      <i class="fa-solid fa-chevron-left"></i><span>الاسئلة الشائعة</span>
    </h4>
    <CommonQuestionsCompo />
  </div>
</template>
<script>
import CommonQuestionsCompo from "../components/Global/common-questions/CommonQuestionsCompo.vue";
export default {
  name: "CommonQuestions",
  components: {
    CommonQuestionsCompo,
  },
};
</script>
<style lang="scss" scoped>
.common-questions {
  h4 {
    margin-top: 12rem;
  }
}
</style>

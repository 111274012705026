<template>
  <div class="OurTrainingSolutions" id="OurTrainingSolutions">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
        <div class="row">
          <div
            class="col-lg-4 col-md-6 col-sm-12 col-xs-12 col-12"
            v-for="(all, i) in logos"
            :key="i"
          >
            <div class="right">
              <a :href="all.link" target="_blank">
                <div class="moauin">
                  <img :src="all.logo_link" alt="" />

                  <!-- <h4>{{ all[0] }}</h4> -->
                  <i class="fa-solid fa-arrow-left"></i>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <!-- narsa -->
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
        <div class="text-training">
          <h4>
            <img src="@/assets/photos/Group.svg" alt="" />
            {{ parntsolutions.header }}
          </h4>
          <p>
            {{ parntsolutions.description }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "OurTrainingSolutionsss",
  data() {
    return {};
  },
  props: {
    parntsolutions: {
      type: Array,
      required: true,
    },
    parntsolutionsfeature: {
      type: Array,
      required: true,
    },
    logos: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    console.log(this.logos);
  },
};
</script>

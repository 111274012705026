<template>
  <div class="aimed">
    <div class="row">
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
        <div class="arrow">
          <img class="img" :src="parntVission.media" alt="" />
          <img class="arrow-style" src="@/assets/photos/arrow.png" alt="" />
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
        <div class="text-aimed">
          <h4>
            <img src="@/assets/photos/Group.svg" alt="" />
            {{ parntVission.header }}
          </h4>
          <p>
            {{ parntVission.description }}
          </p>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
        <div class="text-aimed">
          <h4>
            <img src="@/assets/photos/Group.svg" alt="" />{{
              paentGoals.header
            }}
          </h4>
          <ol>
            <li v-for="(item, i) in paentGoals.description" :key="i">
              {{ item }}
            </li>
          </ol>
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
        <div class="all-img">
          <img class="img" :src="paentGoals.media" alt="" />
          <img class="arrow-style2" src="@/assets/photos/arrow-2.png" alt="" />
        </div>
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
        <img class="img" :src="paentValues.media" alt="" />
      </div>
      <div class="col-lg-6 col-md-12 col-sm-12 col-xs-12 col-12">
        <div class="text-aimed">
          <h4><img src="@/assets/photos/Group.svg" alt="" />قيمنا</h4>
          <ol>
            <li v-for="(item, i) in paentValues.description" :key="i">
              {{ item }}
            </li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "WeAimed",
  props: {
    parntVission: {
      type: Array,
      required: true,
    },
    paentGoals: {
      type: Array,
      required: true,
    },
    paentValues: {
      type: Array,
      required: true,
    },
  },
};
</script>

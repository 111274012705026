<template>
  <div class="privacy-policy">
    <h4>
      <router-link to="/">الرئيسية</router-link>
      <i class="fa-solid fa-chevron-left"></i><span>سياسه الخصوصيه</span>
    </h4>
    <PrivacyCompo />
  </div>
</template>
<script>
import PrivacyCompo from "@/components/Global/Privacy/PrivacyCompo.vue";
export default {
  name: "PrivacyPolicy",
  components: {
    PrivacyCompo,
  },
};
</script>
<style lang="scss" scoped>
.privacy-policy {
  h4 {
    margin-top: 12rem;
  }
}
</style>

<template>
  <footer>
    <div class="footer">
      <img src="@/assets/photos/logo معيار النجاح .png " alt="" />
      <p>
        {{ footer.description }}
      </p>
      <div class="linkes">
        <router-link to="/" class="nav-link active" aria-current="page"
          >الرئيسية</router-link
        >
        <router-link to="/about" class="nav-link" aria-current="page"
          >من نحن</router-link
        >
        <router-link to="/contact-us" class="nav-link" aria-current="page"
          >تواصل معنا</router-link
        >
      </div>
      <div class="input">
        <i class="fa-solid fa-envelope"></i>
        <form>
          <input type="email" placeholder="ادخل بريدك الألكترونى" />
          <button type="submit" class="Subscribe">اشترك</button>
        </form>
      </div>
      <div class="soial">
        <a :href="linkes.facebook" target="_blank">
          <i class="fa-brands fa-facebook"></i>
        </a>
        <a :href="linkes.twitter" target="_blank"
          ><i class="fa-brands fa-x-twitter"></i
        ></a>
        <a :href="linkes.instagram" target="_blank">
          <i class="fa-brands fa-square-instagram"></i>
        </a>
        <a :href="linkes.linkedin" target="_blank">
          <i class="fa-brands fa-linkedin"></i>
        </a>
        <a :href="linkes.snapchat" target="_blank">
          <i class="fa-brands fa-snapchat"></i>
        </a>
        <a :href="linkes.tiktok" target="_blank">
          <i class="fa-brands fa-tiktok"></i>
        </a>
        <a :href="linkes.youtube" target="_blank">
          <i class="fa-brands fa-youtube"></i>
        </a>
      </div>
    </div>
    <div class="end-footer">
      <h4>All rights reserved @ Success Standard Training Company SSTC</h4>
      <h4>Made by <span>X</span> Coders coding with expert</h4>
    </div>
  </footer>
</template>
<script>
import axios from "axios";
export default {
  name: "FooterApp",
  data() {
    return {
      footer: [],
      linkes: [],
    };
  },
  async mounted() {
    await axios
      .get("https://back.success.sa/public/api/fetch-footer")
      .then((response) => {
        this.footer = response.data.data.footer;
        this.linkes = response.data.data.settings;
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<template>
  <div class="layout">
    <TopNave />
    <Navbar />
    <div class="content">
      <slot></slot>
    </div>
    <FooterApp />
  </div>
</template>
<script>
import TopNave from "./TopNav.vue";
import Navbar from "./Navbar.vue";
import FooterApp from "./FooterApp.vue";

export default {
  components: { Navbar, FooterApp, TopNave },
};
</script>
<style scoped lang="scss"></style>

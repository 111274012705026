<template>
  <div class="common-questions-compo">
    <h4>الاسئلة الشائعة</h4>
    <div class="accordion">
      <div class="accordion" id="accordionPanelsStayOpenExample">
        <div
          class="accordion-item"
          v-for="(accord, index) in accordion"
          :key="index"
        >
          <h2 class="accordion-header" :id="'panelsStayOpen-heading-' + index">
            <button
              class="accordion-button"
              type="button"
              :data-bs-toggle="'collapse'"
              :data-bs-target="'#panelsStayOpen-collapse-' + index"
              :aria-expanded="index === 0 ? 'true' : 'false'"
              :aria-controls="'panelsStayOpen-collapse-' + index"
            >
              {{ accord.quetion }}
            </button>
          </h2>
          <div
            :id="'panelsStayOpen-collapse-' + index"
            class="accordion-collapse collapse"
            :class="{ show: index === 0 }"
            :aria-labelledby="'panelsStayOpen-heading-' + index"
          >
            <div class="accordion-body">
              {{ accord.answre }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "CommonQuestionsCompo",
  data() {
    return {
      accordion: [
        {
          quetion: "هل يمكنني الغاء الحقيبه الخاص بي في اي وقت ؟",
          answre:
            "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد",
        },
        {
          quetion: "هل يمكنني الغاء الحقيبه الخاص بي في اي وقت ؟",
          answre:
            "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد",
        },
        {
          quetion: "هل يمكنني الغاء الحقيبه الخاص بي في اي وقت ؟",
          answre:
            "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد",
        },
      ],
    };
  },
};
</script>

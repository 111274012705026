<template>
  <div class="error-pagecompo">
    <h4>عذراً .. الصفحة غير موجودة !!!</h4>
    <img src="@/assets/photos/error-404-sin-fondo-J7Obn7WnEV.png" alt="" />
    <p>
      الصفحة غير موجودة! تم نقل الصفحة التي تبحث عنها وإزالتها، تمت إعادة
      تسميتها أو ربما لم تكن موجودة على الإطلاق.
    </p>
    <button class="btn-goback" @click="$router.push('/')">
      العوده للرئيسية
    </button>
  </div>
</template>
<script>
export default {
  name: "ErrorPagecompo",
};
</script>
